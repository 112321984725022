import cx from 'classnames';
import { ChevronLeft, ChevronRight, ChevronType } from './Chevron';
import styles from './Controls.module.scss';

export const SolidBlueLeft = ({ width = 20, height = 20 }: ChevronType) => {
  return (
    <span
      data-testid="SolidBlueLeft"
      className={cx(styles.solidBlue, styles.solidBlueLeft)}
    >
      <ChevronLeft width={width} height={height} />
    </span>
  );
};

export const SolidBlueRight = ({ width = 20, height = 20 }: ChevronType) => {
  return (
    <span
      data-testid="SolidBlueRight"
      className={cx(styles.solidBlue, styles.solidBlueRight)}
    >
      <ChevronRight width={width} height={height} />
    </span>
  );
};
