import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { ResponsiveImage, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import { extractVideoId } from 'ui/components/developer/Video/video.helper';
import { PrismicRichText } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';
import styles from './videoModel.module.scss';

const defaultOnHoverPlayerOpts = {
  height: '331',
  width: '375',
  playerVars: {
    autoplay: 1,
    playsinline: 0,
    modestbranding: 1,
    start: 0,
    rel: 0,
    fs: 0,
    controls: 0,
    mute: 1,
  },
};

export default function VideoModel({
  slide,
  ext = undefined,
  showModal = undefined,
  setShowModal = undefined,
  showThumbnail = true,
  highlight = true,
  onHoverPlayerOpts = {},
  buttonType = 'icon-with-text',
}) {
  const [modalIsOpen, setModalIsOpen] = useState(showModal);
  const [isBeingHovered, setIsBeingHovered] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [windowWidth, windowHeight] = useWindowsSize();
  const currentVideoId = extractVideoId(slide.video_url);

  const onHoverPlayerProps = {
    ...onHoverPlayerOpts,
    ...defaultOnHoverPlayerOpts,
  };

  useEffect(() => {
    const setSize = () => {};
    setSize();
    window.addEventListener('keyup', e => {
      setModalIsOpen(false);
    });
    window.addEventListener('resize', setSize);

    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [windowWidth, windowHeight, setModalIsOpen]);

  useEffect(() => {
    setModalIsOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    if (!showThumbnail) {
      setShowModal(modalIsOpen);
    }
  }, [modalIsOpen, setShowModal, showThumbnail]);

  const style = {
    '--overlay-color': highlight ? '#1a1a1abb' : '#464646cc',
  };
  const events = useMemo(() => {
    const handleClick = () => {
      setModalIsOpen(true);
    };

    const clickEvents = {
      onClick: handleClick,
    };

    if (windowWidth <= ResponsiveBreakpoint) return clickEvents;
    return {
      ...clickEvents,
      onMouseEnter: () => setIsBeingHovered(true),
      onMouseLeave: () => {
        setIsBeingHovered(false);
        setVideoPlayed(false);
      },
    };
  }, [windowWidth]);

  const WatchVideoButton = ({ type }) => {
    switch (type) {
      case 'icon-with-text':
        return (
          <button type="button" onClick={() => setModalIsOpen(true)} className={styles.btnVideo}>
            <i className={cn('icon-play', styles['btnVideo-icon'])} />
            {slide.watchVideoLabel}
          </button>
        );

      default:
        return <img src="/img/icons/icon-play-video.svg" className={styles['video-thumb-play-btn']} alt="icon" />;
    }
  };

  const imageAltText = slide.video_thumbnail?.alternativeText || slide.video_thumbnail?.name || 'video';

  const renderThumbnail = () => {
    if (slide.video_thumbnail?.url) {
      return (
        <ResponsiveImage
          src={slide.video_thumbnail.url}
          alt={imageAltText}
          layout="fill"
          objectFit="cover"
          blurHash={slide.video_thumbnail.blurDataURL || null}
        />
      );
    }

    if (slide.poster) {
      return (
        <PrismicNextImage
          className={styles.slidePoster}
          field={slide.poster}
          width={400}
          height={400}
        />
      );
    }
    return null;
  };

  return (
    <>
      {showThumbnail && (
        <div role="presentation" className={cn(styles.slide, ext)} {...events}>
          <div className={styles.slideBg} style={style}>
            {isBeingHovered ? (
              <>
                <VideoPlayer
                  videoId={currentVideoId}
                  onHoverPlayerOpts={onHoverPlayerProps}
                  className={styles.scaleOnHoverVideo}
                  onPlay={() => {
                    setVideoPlayed(true);
                  }}
                />
                {!videoPlayed && renderThumbnail()}
              </>
            ) : (
              renderThumbnail()
            )}
          </div>
          <div className={styles.slideInfo}>
            <div className={styles['slideInfo-wrap']}>
              {slide.developer_name && slide.developer_country && (
                <span className={styles['slideInfo-title']}>
                  {`${slide.developer_name}, ${slide.developer_country}`}
                </span>
              )}
              <span className={styles['slideInfo-subTitle']}>{slide.dev_role}</span>
              <span className={styles['slideInfo-description']}>
                {slide.video_description && (
                  <span className={styles['slideInfo-quote']}>{slide.video_description}</span>
                )}
                {slide.description && (
                  <span className={styles['slideInfo-quote']}>
                    <PrismicRichText field={slide.description} />
                  </span>
                )}
              </span>
            </div>
            <WatchVideoButton type={buttonType} />
          </div>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        style={modalStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalIsOpen(false)}
        contentElement={(props, children) => <div {...props}>{children}</div>}
        ariaHideApp={false}
      >
        <span role="presentation" onClick={() => setModalIsOpen(false)} className={styles.modalClose}>
          close
        </span>
        <VideoPlayer videoId={currentVideoId} />
      </Modal>
    </>
  );
}

const videoOpts = {
  height: '380',
  width: '640',
  playerVars: {
    autoplay: 1,
    playsinline: 0,
    modestbranding: 1,
    start: 0,
    rel: 0,
    fs: 0,
  },
};

const modalStyles = {
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    overflow: 'visible',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

const VideoPlayer = ({ videoId, onHoverPlayerOpts, className, onPlay }) => {
  return (
    <div className="ModalVideoWrap">
      <YouTube
        {...(className && { className })}
        videoId={videoId}
        opts={onHoverPlayerOpts || videoOpts}
        onPlay={() => {
          onPlay?.(true);
        }}
      />
    </div>
  );
};
