import { EmblaCarouselType } from 'embla-carousel-react';
import { createContext, useContext } from 'react';
import { CarouselBreakpoint, CarouselNumberSize } from './Carousel.types';

interface CarouselContext {
  carousel: EmblaCarouselType | null;
  slideSize: string | number;
  slideGap: CarouselNumberSize | null;
  breakpoints: CarouselBreakpoint[] | null;
}

interface ProviderType<T> {
  value?: T;
  children: React.ReactNode;
}

const defaultContextValues = {
  carousel: null,
  slideSize: '100%',
  slideGap: null,
  breakpoints: null,
};

const Context = createContext<CarouselContext>(defaultContextValues);

const useCarouselContext = () => {
  const ctx = useContext(Context);

  if (ctx === null) {
    throw new Error('Carousel.Slide was rendered outside of Carousel context');
  }

  return ctx;
};

const CarouselProvider = ({
  children,
  value = defaultContextValues,
}: ProviderType<CarouselContext>) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { CarouselProvider, useCarouselContext };
