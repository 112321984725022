const YT_EMBED_BASE_URL = 'https://www.youtube.com/embed/';

const oldTiredOfFightingVideoID = '0MNk8UU0iBQ';
const newTiredOfFightingVideoID = 'WFdmshp8dB0';

export const extractVideoId = (url = '') => {
  return (url || '')
    .replace(YT_EMBED_BASE_URL, '')
    .replace(oldTiredOfFightingVideoID, newTiredOfFightingVideoID); // if we're getting the old video id of 'Tired of fighting from' video from the CMS, replace it with the new one.
};

export const constructYoutubeEmbedUrl = id => {
  return YT_EMBED_BASE_URL + id;
};
