export type ChevronType = {
  width?: number;
  height?: number;
};

export const ChevronLeft = ({ width = 24, height = 24 }: ChevronType) => {
  return (
    <svg
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        d="M33.24 5.98039C32.26 5.00039 30.68 5.00039 29.7 5.98039L13.08 22.6004C12.3 23.3804 12.3 24.6404 13.08 25.4204L29.7 42.0404C30.68 43.0204 32.26 43.0204 33.24 42.0404C34.22 41.0604 34.22 39.4804 33.24 38.5004L18.76 24.0004L33.26 9.50039C34.22 8.54039 34.22 6.94039 33.24 5.98039Z"
      />
    </svg>
  );
};

export const ChevronRight = ({ width = 24, height = 24 }: ChevronType) => {
  return (
    <svg
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        d="M14.76 42.0196C15.74 42.9996 17.32 42.9996 18.3 42.0196L34.92 25.3996C35.7 24.6196 35.7 23.3596 34.92 22.5796L18.3 5.95961C17.32 4.97961 15.74 4.97961 14.76 5.95961C13.78 6.93961 13.78 8.51961 14.76 9.49961L29.24 23.9996L14.74 38.4996C13.78 39.4596 13.78 41.0596 14.76 42.0196Z"
      />
    </svg>
  );
};
