/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import { MouseEventHandler, ReactNode, useCallback } from 'react';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { isArrayNotEmpty } from 'ui/utils/helpers/array.helper';
import { useCarouselContext } from '../Carousel.context';
import { CarouselBreakpoint, CarouselNumberSize } from '../Carousel.types';

const slideGapBasedOnSize = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 20,
  xl: 24,
};

export const CAROUSEL_BREAKPOINTS = {
  xs: 576,
  sm: 768,
  md: 1024,
  lg: 1200,
  xl: 1400,
};

export type GetSizeBasedOnBreakpointsType = {
  breakpoints?: Partial<CarouselBreakpoint>[];
  windowWidth?: number;
  slideGap: CarouselNumberSize;
  slideSize: string | number;
};

export const getSizeBasedOnBreakpoints = ({
  breakpoints,
  windowWidth,
  slideGap,
  slideSize,
}: GetSizeBasedOnBreakpointsType) => {
  if (isArrayNotEmpty(breakpoints) && windowWidth) {
    const result = breakpoints.find(ele => {
      const minKey = ele.minWidth;
      const minWidth = CAROUSEL_BREAKPOINTS[minKey];
      if (windowWidth >= minWidth) return true;

      const maxKey = ele.maxWidth;
      const maxWidth = CAROUSEL_BREAKPOINTS[maxKey];

      if (windowWidth < maxWidth) {
        return true;
      }

      return false;
    });

    if (result) return result;

    return {
      slideGap,
      slideSize,
    };
  }

  return {
    slideGap,
    slideSize,
  };
};

export interface CarouselSlideProps {
  children: ReactNode;

  size?: string | number;

  gap?: CarouselNumberSize;

  onClick?: (event: any) => void;

  className?: string;

  addOffset?: boolean;
}

export const CarouselSlide = ({
  children,
  className,
  onClick,
  addOffset = false,
}: CarouselSlideProps) => {
  const {
    carousel,
    slideGap: slideGapProp,
    slideSize: slideSizeProp,
    breakpoints,
  } = useCarouselContext();
  const [windowWidth] = useWindowsSize();

  const isMobile = windowWidth <= CAROUSEL_BREAKPOINTS.sm;

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (carousel?.clickAllowed()) {
        onClick?.(event);
      }
    },
    [carousel, onClick],
  );

  const { slideSize, slideGap } = getSizeBasedOnBreakpoints({
    windowWidth,
    slideGap: slideGapProp,
    slideSize: slideSizeProp,
    breakpoints,
  });

  const size = typeof slideSize === 'number' ? `${slideSize}px` : slideSize;
  const gap =
    typeof slideGap === 'string'
      ? `${slideGapBasedOnSize[slideGap] || slideGap}px`
      : '0px';

  const offsetValue = 'max(calc((100vw - var(--container-width)) / 2), 30px)';
  const mobileOffsetValue = 'var(--gutter)';

  const offset = addOffset
    ? isMobile
      ? mobileOffsetValue
      : offsetValue
    : '0px';
  return (
    <div
      role="button"
      tabIndex={0}
      className={cx(className)}
      onClick={handleClick}
      style={{
        flex: `0 0 calc(${size} + ${gap} + ${offset})`,
        paddingLeft: offset,
      }}
    >
      {children}
    </div>
  );
};
