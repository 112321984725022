import { Children, isValidElement, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import cx from 'classnames';
import Autoplay from 'embla-carousel-autoplay';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { clamp } from 'ui/utils/clamp';
import { CarouselProvider } from './Carousel.context';
import { CarouselProps } from './Carousel.types';
import classes from './Carousel.module.scss';
import { CarouselSlide, getSizeBasedOnBreakpoints } from './CarouselSlide/CarouselSlide';
import { ChevronLeft, ChevronRight } from './Controls/Chevron';

const _Carousel = (props: CarouselProps) => {
  const {
    children,
    className,
    classNames,
    getEmblaApi,
    getSelected,
    onNextSlide,
    onPreviousSlide,
    nextControlIcon,
    previousControlIcon,
    indicators: customIndicators,
    breakpoints,
    slideSize = '100%',
    slideGap = 0,
    align = 'start',
    slidesToScroll = 1,
    draggable = true,
    dragFree = false,
    loop = false,
    speed = 5,
    initialSlide = 0,
    inViewThreshold = 1,
    withControls = true,
    withIndicators = false,
    autoPlay = false,
    autoPlayDelay = 4000,
    currentSlide = 0,
    carouselRef = {},
    handleCanScrollState,
  } = props;

  const autoplayRef = useRef(Autoplay({ delay: autoPlayDelay }));
  const parentRef = useRef(null);
  const [selected, setSelected] = useState(0);
  const [slidesCount, setSlidesCount] = useState(0);
  const [windowWidth] = useWindowsSize();

  const {
    withControls: withControlsBreakpointBased,
    withIndicators: withIndicatorsBreakpointBased,
    draggable: draggableBreakpointBased,
    align: alignBreakpointBased,
    loop: loopBreakpointBased,
    slidesToScroll: slidesToScrollBreakpointBased,
  } = getSizeBasedOnBreakpoints({
    windowWidth,
    slideGap,
    slideSize,
    breakpoints,
  });
  const emblaPlugins = autoPlay ? [autoplayRef.current] : [];
  const numOfSlides = Children.toArray(children).length;
  const [emblaRefElement, embla] = useEmblaCarousel(
    {
      startIndex: initialSlide,
      loop: loopBreakpointBased ?? loop,
      align: alignBreakpointBased ?? align,
      slidesToScroll: slidesToScrollBreakpointBased ?? slidesToScroll,
      draggable: draggableBreakpointBased ?? draggable,
      dragFree,
      speed,
      inViewThreshold,
    },
    [...emblaPlugins],
  );

  const handleScroll = useCallback((index: number) => embla && embla.scrollTo(index), [embla]);

  useEffect(() => {
    if (!embla) return;
    handleScroll(currentSlide);
  }, [currentSlide]);
  const canScrollPrev = embla?.canScrollPrev() ?? false;
  const canScrollNext = embla?.canScrollNext() ?? false;

  useImperativeHandle(carouselRef, () => ({
    handlePrevious,
    handleNext,
    scrollSnapList: embla?.scrollSnapList
  }));

  useEffect(() => {
    if(handleCanScrollState) {
      handleCanScrollState({canScrollNext, canScrollPrev});
    }
  }, [canScrollNext, canScrollPrev])

  const handleSelect = useCallback(() => {
    if (!embla) return;
    setSelected(embla.selectedScrollSnap());
  }, [embla, setSelected]);

  const handlePrevious = useCallback(() => {
    embla?.scrollPrev();
    onPreviousSlide?.();
  }, [embla, onPreviousSlide]);

  const handleNext = useCallback(() => {
    embla?.scrollNext();
    onNextSlide?.();
  }, [embla, onNextSlide]);

  useEffect(() => {
    if (embla) {
      getEmblaApi?.(embla);

      handleSelect();
      setSlidesCount(embla.scrollSnapList().length);
      embla.on('select', handleSelect);
      return () => {
        embla.off('select', handleSelect);
      };
    }

    return undefined;
  }, [embla, getEmblaApi, handleSelect]);

  useEffect(() => {
    getSelected?.(selected);
  }, [getSelected, selected]);

  useEffect(() => {
    if (autoPlay) {
      parentRef?.current?.addEventListener('mouseover', () => {
        autoplayRef.current.stop();
      });
      parentRef?.current?.addEventListener('mouseout', () => {
        autoplayRef.current.play();
      });
    }
  }, [autoPlay, parentRef]);

  useEffect(() => {
    if (embla) {
      embla.reInit();
      setSlidesCount(embla.scrollSnapList().length);
      setSelected(currentSelected => clamp(currentSelected, 0, numOfSlides - 1));
    }
  }, [embla, numOfSlides]);

  const indicators =
    customIndicators ??
    Array(slidesCount)
      .fill(0)
      .map((_, index) => (
        <button
          key={index}
          data-active={index === selected}
          data-testid={`carousel-indicator-${index + 1}`}
          aria-hidden
          tabIndex={-1}
          onClick={() => handleScroll(index)}
          className={cx(classes.indicator, {
            [classes.active]: selected === index,
          })}
        />
      ));

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <CarouselProvider
        value={{
          slideGap,
          slideSize,
          carousel: embla,
          breakpoints,
        }}
      >
        <div className={cx(classes.root, className)} ref={parentRef} data-testid="carousel-parent">
          <div ref={emblaRefElement}>
            <div className={classes.container}>{children}</div>
          </div>

          {(withIndicatorsBreakpointBased ?? withIndicators) && (
            <div className={cx(classes.indicators, classNames?.indicators)} data-testid="carousel-indicators">
              {indicators}
            </div>
          )}
        </div>
        {(withControlsBreakpointBased ?? withControls) && (
          <div className={classNames?.controls} data-testid="carousel-controls">
            <button
              data-testid="carousel-previous-control"
              onClick={handlePrevious}
              className={cx(classes.defaultControl, classes.defaultControlPrev, classNames?.previousControlIcon)}
              tabIndex={canScrollPrev ? 0 : -1}
            >
              {isValidElement(previousControlIcon) ? previousControlIcon : <ChevronLeft />}
            </button>

            <button
              data-testid="carousel-next-control"
              onClick={handleNext}
              className={cx(classes.defaultControl, classes.defaultControlNext, classNames?.nextControlIcon)}
              tabIndex={canScrollNext ? 0 : -1}
            >
              {isValidElement(nextControlIcon) ? nextControlIcon : <ChevronRight />}
            </button>
          </div>
        )}
      </CarouselProvider>
    </div>
  );
};

_Carousel.Slide = CarouselSlide;
_Carousel.displayName = 'Carousel';

export const Carousel = _Carousel;
