import cx from 'classnames';
import styles from './Controls.module.scss';

export type SolidArrowType = {
  disabeld?: boolean;
  invert?: boolean;
  circle?: boolean;
  className?: string;
  containerClassName?: string;
  scrollBarWidth?: number;
};

export function SolidArrowLeft({
  disabeld,
  invert,
  circle,
  className,
  containerClassName,
}: SolidArrowType) {
  return (
    <div
      className={cx(
        styles.container,
        styles.containerLeft,
        'mobile-hidden',
        {
          [styles.containerDisabled]: disabeld,
          [styles.invert]: invert,
          [styles.circle]: circle,
          [styles.circle__left]: circle,
        },
        containerClassName,
      )}
      data-testid="SolidArrowLeft"
    >
      <svg
        className={cx(
          styles.solidArrow,
          styles.solidArrowLeft,
          {
            [styles.arrowDisabled]: disabeld,
          },
          className,
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        data-testid="SolidArrowLeftSVG"
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    </div>
  );
}

export function SolidArrowRight({
  disabeld,
  invert,
  circle,
  containerClassName,
  className,
}: SolidArrowType) {
  return (
    <div
      className={cx(
        styles.container,
        styles.containerRight,
        'mobile-hidden',
        {
          [styles.containerDisabled]: disabeld,
          [styles.invert]: invert,
          [styles.circle]: circle,
          [styles.circle__right]: circle,
        },
        containerClassName,
      )}
      data-testid="SolidArrowRight"
    >
      <svg
        className={cx(
          styles.solidArrow,
          styles.solidArrowRight,
          {
            [styles.arrowDisabled]: disabeld,
          },
          className,
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        data-testid="SolidArrowRightSVG"
      >
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    </div>
  );
}
